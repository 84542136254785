import { Courier, AmbroDefaultPackageType } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import {
  ShippingService,
  getInpostCourierService,
  getZadbanoDeliveryStandardConstants,
  getZadbanoOrderTypeConstants,
} from "constants/shippingServiceConstants";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { CollapsibleSection } from "pages/logistics/shared/CollapsibleSection";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { zadbanoActions } from "api/shipping/zadbano/actions";
import { generateDictBasedOnFactoryAndEnum, getAnyErrorKey } from "utilities";
import {
  DeliveryStandardChoices,
  OrderTypeChoice,
  ServiceTimeWindowChoices,
} from "api/shipping/zadbano/enum";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { inpostActions } from "api/shipping/inpost/actions";
import { CURRENCY_TYPE, currenciesOptions } from "CONSTANTS";
import { InpostCourierService } from "api/shipping/inpost/enum";

interface Props {
  courier: Courier;
}

export const DefaultOptionsSection = (props: Props) => {
  switch (props.courier.provider) {
    case ShippingService.AMBRO: {
      return <Ambro {...props} />;
    }
    case ShippingService.DPD: {
      return <DPD {...props} />;
    }
    case ShippingService.GLS: {
      return <GLS {...props} />;
    }
    case ShippingService.MEBEL_TAXI: {
      return null;
    }
    case ShippingService.SPT: {
      return null;
    }
    case ShippingService.POCZTEX: {
      return null;
    }
    case ShippingService.ZADBANO: {
      return <Zadbano {...props} />;
    }
    case ShippingService.BJ_LOGISTICS: {
      return null;
    }
    case ShippingService.INPOST: {
      return <Inpost {...props} />;
    }
    case ShippingService["NOT CONNECTED"]: {
      return null;
    }

    default:
      const exhaustiveCheck: never = props.courier.provider;
      throw new Error(`Unhandled courier case: ${exhaustiveCheck}`);
  }
};

const Zadbano = ({ courier }: Props) => {
  const { data, isLoading, error } = zadbanoActions.useDefaults(courier.id);

  if (isLoading) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <Spinner size={24} />
      </RightPanelSection>
    );
  }

  if (error) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </RightPanelSection>
    );
  }

  assertIsDefined(data);

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <InfoLabel title="godziny dostaw kuriera" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={Object.entries(ServiceTimeWindowChoices).map(([key, text]) => ({
            text,
            type: MenuItemType.TEXT,
            value: text,
          }))}
          mutationHook={zadbanoActions.usePatchDefaults}
          transformQueryData={defaultCustomerServiceTimeWindow => ({
            id: courier.id,
            toUpdate: {
              defaultCustomerServiceTimeWindow: defaultCustomerServiceTimeWindow as ServiceTimeWindowChoices,
            },
          })}
          selected={data.defaultCustomerServiceTimeWindow}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj dostawy" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={generateDictBasedOnFactoryAndEnum(
            getZadbanoDeliveryStandardConstants,
            DeliveryStandardChoices,
          ).map(({ value, key }) => ({
            text: value.name,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={zadbanoActions.usePatchDefaults}
          transformQueryData={defaultDeliveryStandard => ({
            id: courier.id,
            toUpdate: {
              defaultDeliveryStandard: defaultDeliveryStandard as DeliveryStandardChoices,
            },
          })}
          selected={data.defaultDeliveryStandard}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj usługi" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={generateDictBasedOnFactoryAndEnum(
            getZadbanoOrderTypeConstants,
            OrderTypeChoice,
          ).map(({ value, key }) => ({
            text: value.name,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={zadbanoActions.usePatchDefaults}
          transformQueryData={defaultOrderType => ({
            id: courier.id,
            toUpdate: {
              defaultOrderType: defaultOrderType as OrderTypeChoice,
            },
          })}
          selected={data.defaultOrderType}
        />
      </InfoLabel>
      <InfoLabel title="nazwa magazynu" className="mt-1">
        <TextField.Async
          placeholder="Wpisz nazwę magazynu"
          mutationHook={zadbanoActions.usePatchDefaults}
          transformQueryData={pickupWarehouseCode => ({
            id: courier.id,
            toUpdate: {
              pickupWarehouseCode,
            },
          })}
          value={data.pickupWarehouseCode}
        />
      </InfoLabel>
      <InfoLabel title="nazwa sprzedawcy" className="mt-1">
        <TextField.Async
          placeholder="Wpisz nazwę sprzedawcy"
          mutationHook={zadbanoActions.usePatchDefaults}
          transformQueryData={vendor => ({
            id: courier.id,
            toUpdate: {
              vendor,
            },
          })}
          value={data.vendor}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const GLS = ({ courier }: Props) => {
  const glsDefaultAdditionalServicesMutation = shippingActions.useCourierPatchMutation();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={courier.glsDefaultAdditionalServicesOptions}
          selectedOptions={courier.glsDefaultAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      courier.glsDefaultAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const glsDefaultAdditionalServices = status
                        ? [...courier.glsDefaultAdditionalServices, option.id]
                        : courier.glsDefaultAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      glsDefaultAdditionalServicesMutation.mutate({
                        id: courier.id,
                        toUpdate: {
                          glsDefaultAdditionalServices,
                        },
                      });
                    }}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};

const Inpost = ({ courier }: Props) => {
  const { data, isLoading, error } = inpostActions.useDefaults(courier.id);

  if (isLoading) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <Spinner size={24} />
      </RightPanelSection>
    );
  }

  if (error) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </RightPanelSection>
    );
  }

  assertIsDefined(data);

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <InfoLabel title="usługa email" className="mt-1">
        <Checkbox.Async
          checked={data.defaultHasEmailService}
          label=""
          mutationHook={inpostActions.usePatchDefaults}
          size="sm"
          transformQueryData={defaultHasEmailService => ({
            id: courier.id,
            toUpdate: { defaultHasEmailService },
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa zwrotów" className="mt-1">
        <Checkbox.Async
          checked={data.defaultHasReturnService}
          label=""
          mutationHook={inpostActions.usePatchDefaults}
          size="sm"
          transformQueryData={defaultHasReturnService => ({
            id: courier.id,
            toUpdate: { defaultHasReturnService },
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa doręczenia w sobotę" className="mt-1">
        <Checkbox.Async
          checked={data.defaultHasSaturdayService}
          label=""
          mutationHook={inpostActions.usePatchDefaults}
          size="sm"
          transformQueryData={defaultHasSaturdayService => ({
            id: courier.id,
            toUpdate: { defaultHasSaturdayService },
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa SMS" className="mt-1">
        <Checkbox.Async
          checked={data.defaultHasSmsService}
          label=""
          mutationHook={inpostActions.usePatchDefaults}
          size="sm"
          transformQueryData={defaultHasSmsService => ({
            id: courier.id,
            toUpdate: { defaultHasSmsService },
          })}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj usługi" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={generateDictBasedOnFactoryAndEnum(
            getInpostCourierService,
            InpostCourierService,
          ).map(({ value, key }) => ({
            text: value.text,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={inpostActions.usePatchDefaults}
          transformQueryData={defaultInpostCourierService => ({
            id: courier.id,
            toUpdate: {
              defaultInpostCourierService: defaultInpostCourierService as InpostCourierService,
            },
          })}
          selected={data.defaultInpostCourierService}
        />
      </InfoLabel>
      <InfoLabel title="kwota ubezpieczenia" className="mt-1">
        <TextField.Async
          placeholder="Wpisz kwotę"
          type="number"
          mutationHook={inpostActions.usePatchDefaults}
          transformQueryData={defaultInsuranceServiceAmount => ({
            id: courier.id,
            toUpdate: {
              defaultInsuranceServiceAmount: Number(defaultInsuranceServiceAmount),
            },
          })}
          value={data.defaultInsuranceServiceAmount || 0}
        />
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={currenciesOptions}
          mutationHook={inpostActions.usePatchDefaults}
          transformQueryData={defaultInsuranceServiceCurrency => ({
            id: courier.id,
            toUpdate: {
              defaultInsuranceServiceCurrency: defaultInsuranceServiceCurrency as CURRENCY_TYPE,
            },
          })}
          selected={data.defaultInsuranceServiceCurrency}
        />
      </InfoLabel>
      <InfoLabel title="identyfikator organizacji" className="mt-1">
        <TextField.Async
          placeholder="Wpisz identyfikator organizacji"
          mutationHook={inpostActions.usePatchDefaults}
          transformQueryData={organizationId => ({
            id: courier.id,
            toUpdate: {
              organizationId,
            },
          })}
          value={data.organizationId}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const DPD = ({ courier }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="identyfikator klienta">
        <TextField.Async
          size="default"
          placeholder="Wpisz identyfikator klienta"
          mutationHook={shippingActions.useCourierPatchMutation}
          transformQueryData={dpdMasterFid => ({ id: courier.id, toUpdate: { dpdMasterFid } })}
          value={courier.dpdMasterFid}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const Ambro = ({ courier }: Props) => {
  const ambroDefaultPackageTypeMutation = shippingActions.useCourierPatchMutation();
  const ambroDefaultServiceMutation = shippingActions.useCourierPatchMutation();
  const ambroDefaultAdditionalServicesMutation = shippingActions.useCourierPatchMutation();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="domyślny typ paczki">
        <Select
          items={Object.values(AmbroDefaultPackageType).map(option => ({
            value: option,
            text: option,
            type: MenuItemType.TEXT,
          }))}
          onChange={ambroDefaultPackageType => {
            assertIsDefined(ambroDefaultPackageType);
            ambroDefaultPackageTypeMutation.mutate({
              id: courier.id,
              toUpdate: {
                ambroDefaultPackageType: ambroDefaultPackageType as AmbroDefaultPackageType,
              },
            });
          }}
          selected={courier.ambroDefaultPackageType}
        />
      </InfoLabel>
      <InfoLabel title="domyślny typ usługi">
        <Select
          items={courier.ambroServicesOptions.map(option => ({
            value: option.id,
            text: option.name,
            type: MenuItemType.TEXT,
          }))}
          onChange={id => {
            const ambroDefaultService = courier.ambroServicesOptions.find(
              service => service.id === id,
            );
            assertIsDefined(ambroDefaultService);
            ambroDefaultServiceMutation.mutate({
              id: courier.id,
              toUpdate: { ambroDefaultService: ambroDefaultService.id },
            });
          }}
          selected={courier.ambroDefaultService}
        />
      </InfoLabel>
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={courier.ambroAdditionalServicesOptions}
          selectedOptions={courier.ambroDefaultAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      courier.ambroDefaultAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const ambroDefaultAdditionalServices = status
                        ? [...courier.ambroDefaultAdditionalServices, option.id]
                        : courier.ambroDefaultAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      ambroDefaultAdditionalServicesMutation.mutate({
                        id: courier.id,
                        toUpdate: {
                          ambroDefaultAdditionalServices,
                        },
                      });
                    }}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};
