import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./CreateCourierModal.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Courier, CreateCourier } from "api/shipping/models";
import { validationSchema } from "./validationSchema";
import { shippingActions } from "api/shipping/actions";
import { ShippingService, shippingServiceConstants } from "constants/shippingServiceConstants";
import { Select } from "components/miloDesignSystem/molecules/select";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  close: () => void;
}

const initialValues: CreateCourier = {
  name: "",
  provider: ShippingService.AMBRO,
  username: "",
  password: "",
};

const providers: SelectProps["items"] = Object.entries(shippingServiceConstants).map(
  ([id, values]) => ({
    type: MenuItemType.TEXT,
    value: id,
    text: values.name,
  }),
);

export const CreateCourierModal = ({ close }: Props) => {
  const handleSubmit = shippingActions.usePostCourier(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj integrację z kurierem
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, errors }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <Select.Form
                className="mb-3"
                label="Serwis"
                name="provider"
                items={providers}
                textFieldProps={{ size: "default" }}
              />
              <DefaultOptionsSection provider={values.provider} />
              <div className="mb-3">
                <TextField.Form name="name" label="Nazwa" size="default" />
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

interface DefaultOptionsSectionProps {
  provider: Courier["provider"];
}

export const DefaultOptionsSection = (props: DefaultOptionsSectionProps) => {
  switch (props.provider) {
    case ShippingService.AMBRO: {
      return null;
    }
    case ShippingService.DPD: {
      return <DPD {...props} />;
    }
    case ShippingService.GLS: {
      return null;
    }
    case ShippingService.MEBEL_TAXI: {
      return null;
    }
    case ShippingService.SPT: {
      return null;
    }
    case ShippingService.POCZTEX: {
      return null;
    }
    case ShippingService.ZADBANO: {
      return null;
    }
    case ShippingService.BJ_LOGISTICS: {
      return null;
    }
    case ShippingService.INPOST: {
      return null;
    }
    case ShippingService["NOT CONNECTED"]: {
      return null;
    }

    default:
      const exhaustiveCheck: never = props.provider;
      throw new Error(`Unhandled courier case: ${exhaustiveCheck}`);
  }
};

const DPD = (_: DefaultOptionsSectionProps) => {
  return (
    <div className="mb-3">
      <TextField.Form name="dpdMasterFid" label="Identyfikator użytkownika" size="default" />
    </div>
  );
};
